import { useEffect, useState } from "react";
import { Col, Row, Button, Spin, message, Upload } from "antd";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { ADMIN, UPLOADS_URL } from "../../config/constants/api";
import DashbordSidebar from "../../components/DashboardSidebar";
import { Get } from "../../config/api/get";
import { Put } from "../../config/api/put";
import { FaArrowLeftLong } from "react-icons/fa6";
import avatar from "../../assets/avatar.png";
import swal from "sweetalert";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { FaCheckCircle } from "react-icons/fa";
import { extractDate } from "../../config/helpers";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const DropzoneFiltercards = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const [isBanned, setIsBanned] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const getUserDetails = () => {
    setLoading(true);
    Get(`${ADMIN.getUser}${id}`, token)
      .then((response) => {
        setUser(response?.data?.user);
        setProfile(response?.data?.profile);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error fetching user details", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const banUser = (id, status) => {
    Put(`${ADMIN.toggleUser}${id}`, token, { status })
      .then((response) => {
        if (response.status) {
          swal("System Alert!", response?.message, "success");
          setIsBanned(!isBanned);
        }
      })
      .catch((err) => {
        let message = err?.response?.data?.message;
        console.log(":::;", err);
        if (message) swal("Oops!", message, "error");
      });
  };
  const [imageUrl, setImageUrl] = useState("");
  const handleChangepro = (info) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
        setImageObject(
          info?.fileList[info?.fileList?.length - 1]?.originFileObj
        );
        setLoading(false);
        setImageUrl(url);
        let data = new FormData();
        data.append(
          "image",
          info?.fileList[info?.fileList?.length - 1]?.originFileObj
        );
        Put(`${ADMIN.assignBadge}${id}`, token, data, null, "multipart")
          .then((response) => {
            if (response?.status) {
              swal("System Alert!", response?.message, "success");
              getUserDetails();
            }
          })
          .catch((err) => {
            let message = err?.response?.data?.message;
            console.log(":::;", err);
            if (message) swal("Oops!", message, "error");
          });
      }
    );
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
        cursor: "pointer",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Give Badge
      </div>
    </button>
  );
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("Invalid Uplaod, You can only upload image files!");
    }
    return isImage;
  };

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    {!loading ? (
                      <div className="about-us-section">
                        {user && (
                          <div className="bg-parent">
                            <Row
                              align={"middle"}
                              style={{ marginBottom: "15px" }}
                            >
                              <Col lg={14}>
                                <div class="arrow-box2">
                                  <div>
                                    <FaArrowLeftLong
                                      className="arrow"
                                      onClick={() => navigate(-1)}
                                    />
                                  </div>
                                  <h3 className="main-heading">User Details</h3>
                                </div>
                              </Col>
                              <Col lg={10} style={{ textAlign: "end" }}>
                                <Button
                                  type=""
                                  className="web-btn"
                                  onClick={() => {
                                    if (isBanned) {
                                      banUser(user._id, "ACTIVE");
                                    } else {
                                      banUser(user._id, "INACTIVE");
                                    }
                                  }}
                                >
                                  {isBanned ? "UNBAN USER" : "BAN USER"}
                                </Button>
                              </Col>
                            </Row>
                            <Row gutter={[16, 16]}>
                              <Col
                                lg={24}
                                md={24}
                                sm={24}
                                style={{ textAlign: "end" }}
                              >
                                {user?.badge && (
                                  <h5 className="verified-tag">
                                    <FaCheckCircle /> User Verified
                                  </h5>
                                )}
                              </Col>
                            </Row>

                            <Row
                              gutter={[16, 16]}
                              align={""}
                              justify={"space-between"}
                            >
                              <Col md={10} lg={10} xl={8}>
                                {user?.image ? (
                                  <div className="wrapper-group-1000001858">
                                    <img
                                      src={UPLOADS_URL + user?.image}
                                      alt=""
                                      className="img-fluid"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                ) : (
                                  <div className="wrapper-group-1000001858">
                                    <img
                                      src={avatar}
                                      alt=""
                                      className="img-fluid"
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                )}
                              </Col>
                              <Col md={14} lg={14} xl={14}>
                                <Row gutter={[16, 16]}>
                                  <Col lg={12}>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">First Name</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.firstName}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Preferred Name</p>
                                      </div>
                                      <div className="jake-dawson1">
                                      {user?.preferredName}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Date Of Birth</p>
                                      </div>
                                      <div className="jake-dawson1">
                                      {extractDate(user?.dateOfBirth)}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Phone Number</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.mobile}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Emergency Contact No</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.emergencyContact}
                                      </div>
                                    </div>
                                  
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Email</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.email}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Street Address</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.streetAddressOne}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Street Address 2</p>
                                      </div>
                                      <div className="jake-dawson1">
                                      {user?.streetAddressTwo}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Skydiving USPA #</p>
                                      </div>
                                      <div className="jake-dawson1">
                                      USPA number
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Gov ID Card Front</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.govIdFront && (
                                          <div className="wrapper-group-1000001858">
                                              <img
                                                src={UPLOADS_URL + user?.govIdFront}
                                                alt=""
                                                className="img-fluid"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  objectPosition: "center",
                                                }}
                                              />
                                           
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Skydiving License Front</p>
                                      </div>
                                      <div className="jake-dawson1">
                                      {user?.licenseFront && (
                                          <div className="wrapper-group-1000001858">
                                              <img
                                                src={UPLOADS_URL + user?.licenseFront}
                                                alt=""
                                                className="img-fluid"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  objectPosition: "center",
                                                }}
                                              />
                                           
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </Col>


                                  <Col lg={12}>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Last Name</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.lastName}
                                      </div>
                                    </div>
                                    {/* <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Pilot Number</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.pilotNo}
                                      </div>
                                    </div> */}
                                    {/* <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Badge</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.pilotNo && (
                                          <div className="wrapper-group-1000001858">
                                            {user?.badge ? (
                                              <img
                                                src={UPLOADS_URL + user?.badge}
                                                alt=""
                                                className="img-fluid"
                                                style={{
                                                  width: "120px",
                                                  height: "120px",
                                                }}
                                              />
                                            ) : (
                                              <Upload
                                                name="image"
                                                showUploadList={false}
                                                style={{
                                                  position: "relative",
                                                }}
                                                onChange={handleChangepro}
                                                beforeUpload={beforeUpload}
                                              >
                                                {" "}
                                                <div
                                                  style={{
                                                    height: "120px",
                                                    width: "120px",
                                                    border: "1px solid gray",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    borderRadius: "100px",
                                                  }}
                                                >
                                                  {imageUrl ? (
                                                    <img
                                                      src={imageUrl}
                                                      alt="avatar"
                                                      style={{
                                                        width: "100px",
                                                        height: "100px",
                                                        objectPosition:
                                                          "center",
                                                        objectFit: "cover",
                                                        borderRadius: "100px",
                                                      }}
                                                    />
                                                  ) : (
                                                    uploadButton
                                                  )}
                                                </div>{" "}
                                              </Upload>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div> */}
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Zip Code</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.zipCode}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Gender</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.gender}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Home DZ/FBO</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.homeDZFBO}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Country</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.country}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">City</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.city}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">State</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.state}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Pilot License</p>
                                      </div>
                                      <div className="jake-dawson1">
                                      {user?.pilotLicense}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">USPA #</p>
                                      </div>
                                      <div className="jake-dawson1">
                                      {user?.uspaNo}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Gov ID Card Back</p>
                                      </div>
                                      <div className="jake-dawson1">
                                        {user?.govIdBack && (
                                          <div className="wrapper-group-1000001858">
                                              <img
                                                src={UPLOADS_URL + user?.govIdBack}
                                                alt=""
                                                className="img-fluid"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  objectPosition: "center",
                                                }}
                                              />
                                           
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="p-20">
                                      <div className="">
                                        <p className="p-5">Pilot License Card</p>
                                      </div>
                                      <div className="jake-dawson1">
                                      <div className="jake-dawson1">
                                        {user?.licenseCard && (
                                          <div className="wrapper-group-1000001858">
                                              <img
                                                src={UPLOADS_URL + user?.licenseCard}
                                                alt=""
                                                className="img-fluid"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                  objectPosition: "center",
                                                }}
                                              />
                                           
                                          </div>
                                        )}
                                      </div>
                                      </div>
                                    </div>
                                  </Col>


                                  <Col lg={24}>
                                    <div className="frame-group">
                                      <div className="">
                                        <Button
                                          type=""
                                          block
                                          size={"large"}
                                          style={{ marginBottom: "10px" }}
                                          className="web-btn"
                                          onClick={() =>
                                            navigate("/dropZoneManagement/reservationLogs", {state : {userId: id}})
                                          }
                                        >
                                          View DZ Booking Logs
                                        </Button>
                                      </div>
                                      <div className="">
                                        <Button
                                          type=""
                                          block
                                          size={"large"}
                                          style={{ marginBottom: "10px" }}
                                          className="web-btn2"
                                          onClick={() =>
                                            navigate("/staysManagement/reservationLogs", {state : {userId: id}})
                                          }
                                        >
                                          View Stays Logs
                                        </Button>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <Spin style={{ margin: "250px 600px" }} />
                      </div>
                    )}
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DropzoneFiltercards;
